import { useEffect } from 'react';
import { useState, useMemo, useRef } from 'react';

const PracticeGame = ({ activeBook }) => {

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [numCorrect, setNumCorrect] = useState(0);
    const [numWrong, setNumWrong] = useState(0);
    const [problemWords, setProblemWords] = useState([]);

    const gameType = Math.floor(Math.random() * 2)

    const changeWord = () => {
        setCurrentWordIndex(currentWordIndex + 1)
    }

    const correctCount = useRef(null);
    const wrongCount = useRef(null);

    useEffect(() => {
        if (correctCount.current && numCorrect !== 0 && wrongCount.current && correctCount.current) {
            wrongCount.current.style.color = "black";
            correctCount.current.style.color = "#F3ECB0";
            setTimeout(() => {
                correctCount.current.style.color = "black";
            }, 300)
        }
    }, [numCorrect])

    useEffect(() => {
        if (wrongCount.current !== null && numWrong !== 0) {
            correctCount.current.style.color = "black";
            wrongCount.current.style.color = "#F3ECB0";
            setTimeout(() => {
                wrongCount.current.style.color = "black";
            }, 700)
        }
    }, [numWrong])



    const scrambledArray = useMemo(() => {
        let wordArray = activeBook.words;

        const shuffleWords = (arr) => {
            let currentIndex = arr.length - 1;
            while (currentIndex >= 0) {
                let randomIndex = Math.floor(Math.random() * arr.length);
                let tempValue = arr[randomIndex];
                arr[randomIndex] = arr[currentIndex];
                arr[currentIndex] = tempValue;
                currentIndex--
            }
        }
        shuffleWords(wordArray);
        return wordArray;
    }, [activeBook.words]);

    const scrambledAnswers = useMemo(() => {
        if (currentWordIndex <= scrambledArray.length - 1) {
            const currentTrans = scrambledArray[currentWordIndex].translation;
            let wordSetArray = activeBook.words.filter(wordSet => {
                return wordSet.translation !== currentTrans
            });
            let answerArray = [currentTrans];
            const shuffleWords = (arr) => {
                let currentIndex = arr.length - 1;
                while (currentIndex >= 0) {
                    let randomIndex = Math.floor(Math.random() * arr.length);
                    let tempValue = arr[randomIndex];
                    arr[randomIndex] = arr[currentIndex];
                    arr[currentIndex] = tempValue;
                    currentIndex--
                }
            }
            shuffleWords(wordSetArray);
            wordSetArray.slice(0, 3).forEach(wordSet => (
                answerArray.push(wordSet.translation)
            ));
            shuffleWords(answerArray);
            return answerArray;
        } else {
            return null
        }
    }, [activeBook.words, currentWordIndex, scrambledArray]);

    const randomTranslation = activeBook.words.length > 0 && scrambledArray[Math.floor(Math.random() * scrambledArray.length)].translation;

    const checkIfTrue = (bool) => {
        if ((scrambledArray[currentWordIndex].translation === randomTranslation) === bool) {
            setNumCorrect(numCorrect + 1)
        } else {
            setNumWrong(numWrong + 1);
            setProblemWords([...problemWords, scrambledArray[currentWordIndex].word])
        }
        changeWord();
    }

    const checkAnswer = (answer) => {
        if (scrambledArray[currentWordIndex].translation === answer) {
            setNumCorrect(numCorrect + 1)
        } else {
            setNumWrong(numWrong + 1);
            setProblemWords([...problemWords, scrambledArray[currentWordIndex].word])
        }
        changeWord();
    }

    if (activeBook.words.length < 4) {
        return (
            <>
                <h1>Add words to this wordset so that you can practice them!</h1>
            </>
        )
    }
    if (currentWordIndex > activeBook.words.length - 1 && activeBook.words.length > 0 && numWrong > 0) {
        return (
            <div className='summary'>
                <h1>Total Correct: {numCorrect}</h1>
                <h1>These terms need extra work:</h1>
                <div className="words-list">
                {problemWords.map((word, i) => (
                    <h2 key={`problem${word}${i}`}>{word}</h2>
                ))}
                </div>
               
            </div>

        )
    }
    else if (currentWordIndex > activeBook.words.length - 1 && activeBook.words.length > 0 && numWrong === 0) {
        return (
            <>
                <h1>Great job! You made 0 mistakes!</h1>
            </>
        )

    }
    return (

        <div className='game-container'>
            <div className='current-score'>
                <h5 id="green">{String.fromCharCode(10004)}: <span ref={correctCount}>{numCorrect}</span></h5>
                <h5 id="red">{String.fromCharCode(10005)}: <span ref={wrongCount}>{numWrong}</span></h5>
            </div>
            {gameType === 0 && (
                <>
                    <h5>The translation of <span className='game-translation'>{scrambledArray[currentWordIndex].word}</span> is... </h5>
                    <h6>{randomTranslation}</h6>
                    <div className='option-group'>
                        <button className="answer-button" onClick={() => checkIfTrue(true)}>True</button>
                        <br></br>
                        <button className="answer-button" onClick={() => checkIfTrue(false)}>False</button>
                    </div>

                </>
            )}
            {gameType === 1 && (
                <>
                    <h5>The translation of <span className='game-translation'>{scrambledArray[currentWordIndex].word}</span> is... </h5>
                    <div className='option-group'>
                        {scrambledAnswers.map((translation, i) => (
                            <button key={`${translation}${i}`} className='answer-button' onClick={() => checkAnswer(translation)}>{translation}</button>
                        ))}
                    </div>

                </>
            )}

        </div>
    );
};

export default PracticeGame;