import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TestGame from '../Components/TestGame';
const homeIcon = require('../resources/home.png');

const Test = ({ bookSet }) => {

    const [bookSelection, setBookSelection] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const activeBook = useMemo(() => {
        if (bookSelection) {
            return bookSet.books.filter(book => book.title === bookSelection)[0]
        } else {
            return null;
        }
    }, [bookSelection, bookSet.books])

    return (
        <div className="Words-container">
            <div onClick={() => { navigate('../home') }} className='home-icon clickable'><img id="home-icon" src={homeIcon} alt="go home" /></div>
            {!bookSelection ? (
                <>
                    <h1 className="page-header">Which book would you like to test?</h1>
                    <div className="tiles-container">
                    {bookSet.books.map((book, i) => (
                        <div className="word-set clickable" onClick={() => setBookSelection(book.title)} key={`${book.title}${i}`}><div className="header">{book.title} </div></div>
                    ))}
                    </div>
                   
                </>
            ) :
                (
                    <TestGame activeBook={activeBook} bookSelection={bookSelection} />
                )

            }
        </div>
    );
};

export default Test;