import { useState, useMemo } from 'react';

const TestGame = ({ activeBook }) => {

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [numCorrect, setNumCorrect] = useState(0);
    const [numWrong, setNumWrong] = useState(0);
    const [results, setResults] = useState([]);

    const gameType = Math.floor(Math.random() * 2)

    const changeWord = () => {
        setCurrentWordIndex(currentWordIndex + 1)
    }

    const scrambledArray = useMemo(() => {
        let wordArray = activeBook.words;

        const shuffleWords = (arr) => {
            let currentIndex = arr.length - 1;
            while (currentIndex >= 0) {
                let randomIndex = Math.floor(Math.random() * arr.length);
                let tempValue = arr[randomIndex];
                arr[randomIndex] = arr[currentIndex];
                arr[currentIndex] = tempValue;
                currentIndex--
            }
        }
        shuffleWords(wordArray);
        return wordArray;
    }, [activeBook.words]);

    const scrambledAnswers = useMemo(() => {
        if (currentWordIndex <= scrambledArray.length - 1) {
            const currentTrans = scrambledArray[currentWordIndex].translation;
            let wordSetArray = activeBook.words.filter(wordSet => {
                return wordSet.translation !== currentTrans
            });
            let answerArray = [currentTrans];
            const shuffleWords = (arr) => {
                let currentIndex = arr.length - 1;
                while (currentIndex >= 0) {
                    let randomIndex = Math.floor(Math.random() * arr.length);
                    let tempValue = arr[randomIndex];
                    arr[randomIndex] = arr[currentIndex];
                    arr[currentIndex] = tempValue;
                    currentIndex--

                }
            }
            shuffleWords(wordSetArray);
            wordSetArray.slice(0, 3).forEach(wordSet => (
                answerArray.push(wordSet.translation)
            ));
            shuffleWords(answerArray);
            return answerArray;
        } else {
            return null
        }
    }, [activeBook.words, currentWordIndex, scrambledArray]);

    const randomTranslation = activeBook.words.length > 0 && scrambledArray[Math.floor(Math.random() * scrambledArray.length)].translation;

    const checkIfTrue = (bool) => {
        if ((scrambledArray[currentWordIndex].translation === randomTranslation) === bool) {
            setNumCorrect(numCorrect + 1)
            setResults([...results, {
                word: scrambledArray[currentWordIndex].word,
                result: String.fromCharCode(10004)
            }])
        } else {
            setNumWrong(numWrong + 1);
            setResults([...results, {
                word: scrambledArray[currentWordIndex].word,
                result: String.fromCharCode(10005)
            }])
        }

        changeWord();
    }

    const checkAnswer = (answer) => {
        if (scrambledArray[currentWordIndex].translation === answer) {
            setNumCorrect(numCorrect + 1)
            setResults([...results, {
                word: scrambledArray[currentWordIndex].word,
                result: String.fromCharCode(10004)
            }])
        } else {
            setNumWrong(numWrong + 1);
            setResults([...results, {
                word: scrambledArray[currentWordIndex].word,
                result: String.fromCharCode(10005)
            }])
        }
        changeWord();
    }

    if (activeBook.words.length < 1) {
        return (
            <>
                <h1>Add words to this wordset so that you can practice them!</h1>
            </>
        )
    }
    if (currentWordIndex > activeBook.words.length - 1 && activeBook.words.length > 0) {
        return (
            <>
                <h2 className={`test-score ${(numCorrect / scrambledArray.length * 100) < 70 ? "bad-score":"good-score"} `}>Score: <span >
                    {(numCorrect / scrambledArray.length * 100).toFixed(2)}%</span></h2>
                <div className='results-container'>
                    {results.map(res => (
                        <div className="result-text" key={res.word}>{res.word}: <span className='result-symbol'>{res.result}</span></div>
                    ))}
                </div>

            </>

        )
    }

    return (

        <div className='game-container'>
            <div className='current-score'>
                <h5>Question: {currentWordIndex + 1}/{scrambledArray.length}</h5>
            </div>
            {gameType === 0 && (
                <>
                    <h5>The translation of <span className='game-translation'>{scrambledArray[currentWordIndex].word}</span> is... </h5>
                    <h6>{randomTranslation}</h6>
                    <div className='option-group'>
                        <button className="answer-button" onClick={() => checkIfTrue(true)}>True</button>
                        <br></br>
                        <button className="answer-button" onClick={() => checkIfTrue(false)}>False</button>
                    </div>

                </>
            )}
            {gameType === 1 && (
                <>
                    <h5>The translation of <span className='game-translation'>{scrambledArray[currentWordIndex].word}</span> is... </h5>
                    <div className='option-group'>
                        {scrambledAnswers.map((trans, i) => (
                            <button key={`${trans}${i}`} className='answer-button' onClick={() => checkAnswer(trans)}>{trans}</button>
                        ))}
                    </div>

                </>
            )}

        </div>
    );
};

export default TestGame;