
import { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Words from "./Pages/Words";
import Practice from "./Pages/Practice";
import Test from "./Pages/Test";
function App() {

  const [user, setUser] = useState();
  const [bookSet, setBookSet] = useState();

  const login = (data) => {
    setUser(
      {
        ...data,
      }
    );
  }

  useEffect(() => {
    if (user) {
      try {
        fetch('/api/getBookSets', {
          method: 'post',
          headers: {
            "Content-type": 'application/json'
          },
          body: JSON.stringify({ user_id: user.id })
        })
          .then((res) => res.json())
          .then((bookSet) => {

            setBookSet(bookSet);
          });
      } catch (err) {
        alert(err)
      }
    }
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={!user ? (<Login login={login} />) : (<Home user={user} setUser={setUser} />)}
          />
          <Route
            path="/home"
            element={!user ? (<Login login={login} />) : (<Home user={user} setUser={setUser} />)}
          />
          <Route
            path="/books"
            element={user ? (<Words bookSet={bookSet} setBookSet={setBookSet} />) : (<Login login={login} />)}
          />
          <Route
            path="/login"
            element={<Login login={login} />}
          />
          <Route
            path="/practice"
            element={user ? (<Practice bookSet={bookSet} />) : (<Login login={login} />)}
          />
          <Route
            path="/test"
            element={user ? (<Test bookSet={bookSet} />) : (<Login login={login} />)}
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
