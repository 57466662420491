import { useState, useMemo, useRef, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
const homeIcon = require('../resources/home.png');
const deleteIcon = require('../resources/delete.png');
const backIcon = require('../resources/back.png');

const Words = ({ bookSet, setBookSet }) => {

    const [bookSelection, setBookSelection] = useState();
    const [newBookTitle, setNewBookTitle] = useState();
    const [refresh, setRefresh] = useState(true);
    const [newWords, setNewWords] = useState(
        [
            {
                word: "",
                definition: ""
            }
        ]
    )

    const navigate = useNavigate();

    const addBookWindow = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const activeBook = useMemo(() => {
        if (bookSelection) {
            return bookSet.books.filter(book => book.title === bookSelection)[0]
        } else {
            return null;
        }
    }, [bookSelection, bookSet.books])

    const openAddWord = () => {
        document.getElementById('addWordWindow').style.display = 'flex'
    }

    const closeAddWord = () => {
        document.getElementById('addWordWindow').style.display = 'none';
        document.getElementById("newWordsForm").reset();
        setNewWords(
            [
                {
                    word: "",
                    definition: ""
                }
            ]
        )
    }

    const deleteNewSet = (e, index) => {
        e.target.parentNode.parentNode.style.display = "none";
        newWords[index].word = "";
        newWords[index].translation = "";
    }
    const openAddBook = () => {
        document.getElementById('addBookWindow').style.display = 'flex'
    }

    const closeAddBook = () => {
        document.getElementById('addBookWindow').style.display = 'none'
    }

    const addWordToBook = async () => {
        const tempBookSet = bookSet;
        await newWords.reduce(async (a, newWord) => {
            // Wait for the previous item to finish processing
            await a;
            // Process this item
            if (newWord.definition.length > 0 && newWord.word.length > 0) {
                await fetch('/api/addWordToBook', {
                    method: 'put',
                    headers: {
                        "Content-type": 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            word: newWord.word,
                            translation: newWord.definition,
                            book_title: bookSelection,
                            bookSet_id: bookSet.id
                        }
                    )
                })
                    .then(res => res.json())
                    .then(json => {

                        setNewWords([
                            {
                                word: "",
                                definition: ""
                            }
                        ])

                        for (let i = 0; i < tempBookSet.books.length; i++) {
                            if (tempBookSet.books[i].title === bookSelection) {
                                tempBookSet.books[i].words.push(
                                    {
                                        word: newWord.word,
                                        translation: newWord.definition
                                    }
                                )
                            }
                        }

                    })
                    .catch((err) => {
                        alert(err);
                    })
            }
        }, Promise.resolve());

        document.getElementById('addWordWindow').style.display = 'none';
        document.getElementById('newWordsForm').reset();
        setBookSet(tempBookSet);
    }

    const deleteWordFromBook = (index) => {
        fetch('/api/deleteWordFromBook', {
            method: 'delete',
            headers: {
                "Content-type": 'application/json'
            },
            body: JSON.stringify(
                {
                    word_index: index,
                    book_title: bookSelection,
                    bookSet_id: bookSet.id
                }
            )
        })
            .then(res => res.json())
            .then(json => {
                document.getElementById('addWordWindow').style.display = 'none';
                const tempBookSet = bookSet;
                for (let i = 0; i < tempBookSet.books.length; i++) {
                    if (tempBookSet.books[i].title === bookSelection) {
                        tempBookSet.books[i].words.splice(index, 1)
                    }
                }
                setBookSet(tempBookSet);
                setRefresh(!refresh)
            })
            .catch((err) => {
                alert(err);
            }
            )
    }

    const addBookToSet = () => {
        fetch('/api/addBookToSet', {
            method: 'put',
            headers: {
                "Content-type": 'application/json'
            },
            body: JSON.stringify(
                {
                    book_title: newBookTitle,
                    bookSet_id: bookSet.id
                }
            )
        })
            .then(res => res.json())
            .then(json => {
                document.getElementById('addWordWindow').style.display = 'none';
                let tempBookSet = bookSet;
                tempBookSet.books = [...tempBookSet.books, {
                    title: newBookTitle,
                    words: []
                }]
                setBookSet(tempBookSet);
                setRefresh(!refresh);
                addBookWindow.current.style.display = 'none'
            })
            .catch((err) => {
                alert(err);
            }
            )
    }

    const deleteBookFromSet = (bookTitle) => {
        confirmAlert({
            title: 'Delete Book',
            message: 'Are you sure you want to delete this book?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fetch('/api/deleteBookFromSet', {
                            method: 'delete',
                            headers: {
                                "Content-type": 'application/json'
                            },
                            body: JSON.stringify(
                                {
                                    book_title: bookTitle,
                                    bookSet_id: bookSet.id
                                }
                            )
                        })
                            .then(res => res.json())
                            .then(json => {
                                document.getElementById('addWordWindow').style.display = 'none';
                                let tempBookSet = bookSet;
                                tempBookSet.books = tempBookSet.books.filter(book => book.title !== bookTitle)
                                setBookSet(tempBookSet);
                                setRefresh(!refresh)
                            })
                            .catch((err) => {
                                alert(err);
                            }
                            )
                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
        })

    }

    const updateWord = (newWord, inputIndex) => {
        setNewWords((oldSets) => {
            const tempSets = oldSets;
            tempSets[inputIndex].word = newWord;
            return tempSets;
        })
        if (inputIndex === newWords.length - 1) {
            if (newWords.length > 0 && newWords[inputIndex].definition.length > 0) {
                setNewWords((oldSets) => {
                    const tempSets = oldSets;
                    const newSets = [
                        ...tempSets,
                        {
                            word: "",
                            definition: ""
                        }
                    ]
                    return newSets;
                })
            }
        }
    }

    const updateTranslation = (newTrans, inputIndex) => {
        setNewWords((oldSets) => {
            const tempSets = oldSets;
            tempSets[inputIndex].definition = newTrans;
            return tempSets;
        })
        if (inputIndex === newWords.length - 1) {
            if (newTrans.length > 0 && newWords[inputIndex].word.length > 0) {
                setNewWords((oldSets) => {
                    const tempSets = oldSets;
                    const newSets = [
                        ...tempSets,
                        {
                            word: "",
                            definition: ""
                        }
                    ]
                    return newSets;
                })
            }
        }
    }

    const handleNewWordsSubmit = (e) => {
        e.preventDefault();
        addWordToBook();
    }

    return (
        <div className="Words-container">
            <div className='page-options'>
                <div onClick={() => { navigate('../home') }} className='home-icon clickable'><img id="home-icon" src={homeIcon} alt="go home" /></div>
                <div className={`${!bookSelection && "hidden"} back-icon-container clickable`} onClick={e => setBookSelection()} id="back-button"><img src={backIcon} alt="go back" className="back-icon" /><span>BACK</span></div>
            </div>

            {!bookSelection ? (
                <>
                    <h1 className="page-header">My Word Sets</h1>
                    <button onClick={() => openAddBook()}> Create Word Set</button>
                    <br></br>
                    <div className='tiles-container'>
                    {bookSet.books.map((book, i) => (
                        <div className="word-set-container" key={book.title + i.toString()}>
                             <div onClick={() => setBookSelection(book.title)} className="word-set clickable">
                            <div className="header"  key={book.title}>{book.title}</div>
                            </div>
                            <div className="clickable delete-container" onClick={() => deleteBookFromSet(book.title)}><img src={deleteIcon} alt="delete" className="delete-icon" />
                        </div>
                        </div>
                    ))}
                    </div>
                
                </>
            ) :
                (
                    <>
                            <div className="h1 page-header">{bookSelection} </div>
                        <button onClick={() => openAddWord()}>Add Word</button>
                        <br></br>
                        <div className='word-sets-container'>
                            {activeBook.words.map((wordset, i) => (
                                <div className='word-set' key={`${wordset.translation}${i}`}>
                                    <div className='word-set-pair'>
                                        <h1 className='word'>{wordset.word}:</h1>
                                        <h2 className='translation'>{wordset.translation}</h2>
                                    </div>
                                    <div onClick={() => deleteWordFromBook(i)} className="word-delete-container"><img src={deleteIcon} alt="delete" className="delete-icon" /></div>
                                </div>

                            ))}
                        </div>

                    </>
                )

            }

            <div className='addWindow' id='addWordWindow'>
                <div className='page-options'>
                    <div className={`back-icon-container clickable`} onClick={e => closeAddWord()} id="back-button"><img src={backIcon} alt="go back" className="back-icon" /><span>BACK</span></div>
                </div>

                <br></br>
                <form id="newWordsForm" onSubmit={e => handleNewWordsSubmit(e)}>
                    <button type="submit" >Add to set</button>
                    {
                        newWords.map(((input, i) => (
                            <div key={`wordInput${i}`} className="new-word-set" style={{ display: "flex" }}>
                                <input id='word-input' placeholder='word' onChange={e => updateWord(e.target.value, i)} autoComplete="off"></input>
                                <textarea id='definition-input' placeholder='definition' maxLength={60} onChange={e => updateTranslation(e.target.value, i)} autoComplete="off"></textarea>
                                {i !== 0 && <div className="clickable delete-container" onClick={(e) => deleteNewSet(e, i)}><img src={deleteIcon} alt="delete" className="delete-icon" /></div>}
                            </div>
                        )))
                    }
                </form>


            </div>

            <div className='addWindow' id='addBookWindow' ref={addBookWindow}>
                <div className='page-options'>
                    <div className="back-icon-container clickable" onClick={() => closeAddBook()} id="back-button"><img src={backIcon} alt="go back" className="back-icon" /><span>BACK</span></div>
                </div>

                <h2 className="page-header">Create New Word Set</h2>
                <input id='title-input' placeholder='Word Set Title' onChange={e => setNewBookTitle(e.target.value)}></input>
                <br></br>
                <button onClick={() => addBookToSet()} className='add-word-set-button'>Add WordSet</button>
            </div>



        </div>
    );
};

export default Words;