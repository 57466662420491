import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Greeting from '../Components/Greeting';
const testIcon = require('../resources/test.png');
const pracIcon = require('../resources/practice.png');
const setsIcon = require('../resources/wordsets.png');

const logoutIcon = require('../resources/logout.png')
const Home = ({ user, setUser }) => {

    const navigate = useNavigate();

    const [greeting, setGreeting] = useState(true);

    const goToWords = () => {
        navigate("../books")
    }

    useEffect(() => {
        setTimeout(() => {
            setGreeting(false)
        }, 3000)
        return setGreeting(false);
    }, []
    )

    if (greeting) {
        return (

            <div className="Home-container">
                <Greeting user={user} />
            </div>
        )
    }

    return (
        <div className="Home-container">
            <div className='home-options'>
                <div className='logout clickable' onClick={() => setUser()}><img src={logoutIcon} alt="logout icon" id='logout-icon' />Logout</div>
            </div>
            <div className="menu-options">
                <div onClick={() => goToWords()} className="menu-tile clickable">My Word Sets
                    <div className='menu-tile-icon'><img src={setsIcon} alt='word sets icon'></img></div>
                </div>
                <div className="menu-tile clickable" onClick={() => navigate('../practice')}>Practice
                    <div className='menu-tile-icon'><img src={pracIcon} alt='practice icon'></img></div>
                </div>
                <div className="menu-tile clickable" onClick={() => navigate('../test')}>Test
                    <div className='menu-tile-icon'><img src={testIcon} alt='test icon'></img></div>
                </div>
            </div>

        </div>
    )
};

export default Home;