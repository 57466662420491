import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
const teacherIcon = require('../resources/PngItem_6066071 (1).png')

const Login = ({ login }) => {

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/login') {
            navigate('../login');
        }
    }, [navigate, location])
    // login useStates for function - checkCredantials
    const [emailInp, setEmailInp] = useState('')
    const [passwordInp, setPasswordInp] = useState('')
    const [errorMessage, setErrorMessage] = useState();
    // API LOGIN REQUEST HERE TODO - usestates for inputs//

    const warningMessage = useRef();

    const checkCredentials = (e) => {
        e.preventDefault();
        let loginDetails = {
            email: emailInp,
            password: passwordInp
        }
        fetch('/api/login', {
            method: 'post',
            headers: {
                "Content-type": 'application/json'
            },
            body: JSON.stringify(loginDetails)
        })
            .then(res => res.json())
            .then(user => {
                if (user.data) {
                    login(user.data)
                    navigate('../home');
                } else {
                    setErrorMessage(`username or password incorrect.`)
                    setTimeout(() => {
                        setErrorMessage();
                    }, 4000)
                }
            })
            .catch(() => {
                setErrorMessage(`connection error`)
                setTimeout(() => {
                    setErrorMessage();
                }, 4000)
            }
            )
    }

    // API REGISTER REQUEST HERE TODO - usestates for inputs//
    const register = (email, password, firstName, lastName) => {
        let newUser = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
        }
        fetch('/api/register', {
            method: 'post',
            headers: {
                "Content-type": 'application/json'
            },
            body: JSON.stringify(newUser)
        })
            .then((res) => {
                alert('Successfully Registered. Please login.')
            })
    }



    const registerActive = () => {
        document.getElementById('regForm').style.display = 'flex';
        document.getElementById('loginForm').style.display = 'none';
    }

    const loginActive = () => {
        document.getElementById('regForm').style.display = 'none';
        document.getElementById('loginForm').style.display = 'flex';
    }


    const makeLower = (e) => {
        let targetElement = document.getElementById(e.target.id);
        targetElement.value = targetElement.value.toLowerCase();
    }

    const handleRegister = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const firstname = e.target.firstname.value;
        const lastname = e.target.lastname.value;
        const password = e.target.password.value;
        const passwordConf = e.target.passconfirm.value;

        if (passwordConf === password) {
            register(email, password, firstname, lastname)
            loginActive();
        }

    }

    return (

        <div className="Login-container">

            <div className='login-banner'><img src={teacherIcon} alt='teacher icon'></img></div>
            <div className='login-right'>
            <h1 className='entry-title'>Quiz Gal</h1>
            <div className="entryForm">
                <div className="Login-fields">
                    <form id='loginForm' onSubmit={e => checkCredentials(e)}>
                    <h1>Login</h1>
                        <input required type="text" id="logemail" name="logemail" placeholder="username" onChange={e => setEmailInp(e.target.value)}></input>
                        <input required type="password" id="logpass" name="logpass" placeholder="password" onChange={e => setPasswordInp(e.target.value)}></input>
                        <p>Need to <span onClick={registerActive}>register</span>?</p>
                        <button type="submit"> Login </button>

                    </form>

                    <form onSubmit={e => handleRegister(e)} id='regForm' >
                    <h1>Register</h1>
                        <input required type="text" id="firstname" name="firstname" placeholder="first name" minLength='2' maxLength='20'></input>
                        <input required type="text" id="lastname" name="lastname" placeholder="last name" minLength='2' maxLength='20'></input>
                        <input onChange={(e) => makeLower(e)} required type="text" id="email" name="email" placeholder="username" ></input>
                        <input required type="password" id="password" name="password" placeholder="password"></input>
                        <input required type="password" id="passconfirm" name="passconfirm" placeholder="confirm password"></input>
                        <p>Need to <span onClick={loginActive}>login</span>?</p>
                        <button type="submit" > Register </button>
                    </form>
                </div>
                {errorMessage && <div className="warning" ref={warningMessage}>{errorMessage}</div>}
            </div>
            </div>
           

        </div>

    );
};

export default Login;